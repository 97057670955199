import { Box, Grid, Typography } from "@mui/material";
import { EmployeeProgress } from "../../components/analytics/EmployeeProgress";
import { EmployeeSpend } from "../../components/analytics/EmployeeSpend";
import ProviderSpendChannels from "../../components/analytics/ProviderSpendChannels";
import { Totals } from "../../components/analytics/Totals";
import { CommunicationsBanners } from "../../components/CommunicationsBanners";
import { useData } from "../../utils/useData";

export const Home = () => {
  const { employer, userInfoData } = useData();

  return (
    <Box minWidth="fit-content">
      <CommunicationsBanners />
      <Typography variant="h4">
        Welcome, {userInfoData?.user?.firstName}
      </Typography>
      <Typography variant="subtitle1">
        Your dashboard for {employer.data?.name}
      </Typography>
      <Grid container spacing={2} marginTop={3}>
        <Grid container item spacing={2}>
          <Totals />
        </Grid>
        <Grid container item spacing={2} xs={12} lg={8}>
          <Grid item xs={12}>
            <EmployeeProgress />
          </Grid>
          <Grid item xs={12}>
            <EmployeeSpend />
          </Grid>
        </Grid>
        <Grid container item spacing={2} xs={12} lg={4}>
          <Grid item xs={12}>
            <ProviderSpendChannels />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
