import { AccountCircleOutlined } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Grid,
  InputAdornment,
  TextField,
  Typography,
  useTheme
} from "@mui/material";
import { FormikProps } from "formik";
import { FC } from "react";
import { EmployerFormValuesType } from "../../shared/EmployerForm";

export const Step0Email: FC<{
  formik: FormikProps<EmployerFormValuesType>;
  loading: boolean;
  next: () => void;
}> = ({ formik, loading, next }) => {
  const theme = useTheme();

  return (
    <>
      <Typography variant="h4" fontSize={32} fontWeight={700}>
        Create your account
      </Typography>
      <Typography variant="subtitle1" my={1}>
        We suggest using the email address you use at work
      </Typography>
      <Grid container item xs={12} gap={2} my={1}>
        <TextField
          name="contact.email"
          value={formik.values.contact.email}
          onChange={formik.handleChange}
          error={
            formik.touched.contact?.email &&
            Boolean(formik.errors.contact?.email)
          }
          helperText={
            formik.touched.contact?.email &&
            formik.errors.contact?.email?.replace("contact.email", "Email")
          }
          placeholder="Email"
          fullWidth
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AccountCircleOutlined color="secondary" />
              </InputAdornment>
            )
          }}
        />
        <LoadingButton
          loading={loading}
          disabled={
            !formik.values.contact.email ||
            Boolean(formik.errors.contact?.email)
          }
          variant="contained"
          sx={{ width: "100%", py: 1 }}
          onClick={next}
        >
          Continue
        </LoadingButton>
        <Grid item>
          <Typography fontSize={14}>
            By proceeding, you agree to the{" "}
            <a
              href="https://healthnowhq.com/privacy/"
              style={{ color: theme.palette.grey[600], cursor: "pointer" }}
              target="_"
            >
              Terms of Service
            </a>{" "}
            and{" "}
            <a
              href="https://healthnowhq.com/privacy/"
              style={{ color: theme.palette.grey[600], cursor: "pointer" }}
              target="_"
            >
              Privacy Policy
            </a>
          </Typography>
        </Grid>
      </Grid>

      <Typography fontSize={14} mt={5}>
        Already have an account?{" "}
        <a
          href="/"
          style={{
            color: theme.palette.primary.main,
            cursor: "pointer",
            fontWeight: 500
          }}
        >
          Sign in
        </a>
      </Typography>
    </>
  );
};
