import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  List,
  ListItem,
  Stack,
  Typography
} from "@mui/material";
import { useState } from "react";
import { Plus, Settings } from "react-feather";
import { HnChip } from "../../components/HnChip";
import { AddPlan } from "../../components/plans/AddPlan";
import { EditPlan } from "../../components/plans/EditPlan";
import { LoadingSpinner } from "../../components/shared/LoadingSpinner";
import { MembershipType, Plan } from "../../models";
import { formatCurrency } from "../../utils/formatters";
import { useData } from "../../utils/useData";

export const Plans = () => {
  const { plans, membership } = useData();

  const [addingPlan, setAddingPlan] = useState<boolean>(false);
  const [editingPlan, setEditingPlan] = useState<Plan | undefined>();

  const isPilot = membership.data?.type === MembershipType.PILOT;

  if (plans.isLoading || plans.isIdle) {
    return <LoadingSpinner />;
  }

  if (plans.error || !plans.data) {
    return <p>Something went wrong, please try again later</p>;
  }

  const renderPlan = (plan: Plan) => {
    return (
      <Grid item xs={12} md={6} key={plan.id}>
        <Card>
          <CardContent>
            <Box display="flex" justifyContent="space-between">
              <Box>
                <Typography variant="h5">{plan.name}</Typography>
              </Box>
              <Box>
                <IconButton onClick={() => setEditingPlan(plan)}>
                  <Settings />
                </IconButton>
              </Box>
            </Box>
            <List>
              {plan.tiers.map((tier) => {
                return (
                  <ListItem key={tier.id}>
                    <Box display="flex" width="100%">
                      <Typography>{tier.name}</Typography>
                      <Typography marginLeft="auto">
                        {formatCurrency(tier.amount.amount)}
                      </Typography>
                    </Box>
                  </ListItem>
                );
              })}
              <Box display="flex" flexWrap="wrap" gap={2} mt={3}>
                {plan?.serviceSectors &&
                  plan?.serviceSectors
                    .sort((a, b) => {
                      if (a.name < b.name) {
                        return -1;
                      }
                      if (a.name > b.name) {
                        return 1;
                      }
                      return 0;
                    })
                    .map((sc, i) => {
                      return (
                        <HnChip key={i} variant="outlined">
                          {sc.name}
                        </HnChip>
                      );
                    })}
              </Box>
            </List>
          </CardContent>
        </Card>
      </Grid>
    );
  };

  return (
    <>
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          position: "relative",
          border: "1px solid",
          borderRadius: 1,
          borderColor: "divider",
          boxShadow: "inherit",
          marginBottom: "10px"
        }}
      >
        <Stack
          direction="row"
          spacing={2}
          justifyContent="end"
          alignItems="end"
          sx={{
            padding: 2,
            overflowX: "auto"
          }}
        >
          <Button
            variant="contained"
            onClick={() => setAddingPlan(true)}
            endIcon={<Plus size={16} />}
            size="medium"
            disabled={
              isPilot
                ? true
                : membership.data?.features?.maxPlanCount
                ? plans.data.totalItems >=
                  membership.data?.features?.maxPlanCount
                : false
            }
          >
            Add plan
          </Button>
        </Stack>
      </Card>
      <Grid container spacing={2}>
        {plans.data.items.map(renderPlan)}
      </Grid>
      {editingPlan && (
        <EditPlan
          isPilot={isPilot}
          open={true}
          handleClose={() => setEditingPlan(undefined)}
          plan={editingPlan}
        />
      )}
      {addingPlan && (
        <AddPlan
          isPilot={isPilot}
          open={true}
          handleClose={() => setAddingPlan(false)}
        />
      )}
    </>
  );
};
