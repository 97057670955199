import { HelpOutline } from "@mui/icons-material";
import { Box, Divider, Typography, useTheme } from "@mui/material";
import { extraordinaryColors } from "../../utils/theme";

export const HelpAndSupportFaq = () => {
  const theme = useTheme();

  return (
    <Box bgcolor={extraordinaryColors.blue.main} borderRadius={3} p={2}>
      <Typography fontWeight={600} color="primary">
        Help & Support
      </Typography>
      <Divider sx={{ my: 1, borderColor: theme.palette.primary.main }} />
      <Box display="flex" gap={0.5} color="primary">
        <HelpOutline color="primary" />
        <Typography fontSize={14}>
          Need help setting up HealthNow or have questions/feedback? Visit our{" "}
          <a
            href="https://help-customer.healthnowhq.com/"
            style={{ color: theme.palette.primary.main }}
          >
            {" "}
            help centre
          </a>
          .
        </Typography>
      </Box>
    </Box>
  );
};
