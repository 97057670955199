import { Box, Step, StepLabel, Stepper, Typography } from "@mui/material";
import React, { useState } from "react";
import {
  EditMode,
  Employee, EmployeeAccount,
  Plan,
  PlanType,
  RenewalOptions,
  RenewalStrategy,
  RenewalType,
  ServiceSector,
  Tier
} from "../../models";
import { PlanSettingsForm } from "./PlanSettingsForm";
import { PlanTiersForm } from "./PlanTiersForm";
import { PlanRestrictionsForm } from "./PlanRestrictionsForm";

type PlanDetailsProps = {
  isPilot: boolean;
  employees: Employee[];
  accounts: EmployeeAccount[];
  serviceSectors: ServiceSector[];
  existingPlan?: Plan | null;
  isLoading: boolean;
  onClose: () => void;
  onSave: (name: string,
           serviceSectorIds: string[],
           tiers: Tier[],
           planType: PlanType,
           renewalOptions: RenewalOptions) => void;
};

interface StepContentProps {
  step: number;
}

export const PlanDetails = ({
                              isPilot,
                              employees,
                              accounts,
                              serviceSectors,
                              isLoading,
                              onClose,
                              onSave,
                              existingPlan
                            }: PlanDetailsProps) => {
  const [activeStep, setActiveStep] = useState<number>(0);
  const [name, setName] = useState<string>(existingPlan?.name ?? "");
  const [planType, setPlanType] = useState<PlanType>(existingPlan?.type ?? PlanType.ONGOING);
  const [renewalOptions, setRenewalOptions] = useState<RenewalOptions>(existingPlan?.renewalOptions ?? {
    strategy: RenewalStrategy.RESET,
    type: RenewalType.INDIVIDUAL_START_DATE,
    periodInMonths: 12
  });
  const [tiers, setTiers] = useState<Tier[]>(existingPlan?.tiers ?? []);
  const [serviceSectorIds, setServiceSectorIds] = useState<string[]>(existingPlan?.serviceSectors?.map(x => x.id) ?? []);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSettingsStep = (newName: string,
                              planType: PlanType,
                              newRenewalOptions: RenewalOptions) => {
    setName(newName);
    setPlanType(planType);
    setRenewalOptions(newRenewalOptions);
    handleNext();
  };

  const handlePlanSave = async (serviceSectorIds: string[]) => {
    await onSave(name, serviceSectorIds, tiers, planType, renewalOptions);
  };

  const steps = ["Settings", "Tiers", "Restrictions"];
  const editMode = existingPlan == null ? EditMode.CREATE : EditMode.EDIT;

  const StepContent: React.FC<StepContentProps> = ({ step }) => {
    switch (step) {
      case 0:
        return <PlanSettingsForm
          isPilot={isPilot}
          name={name}
          planType={planType}
          renewalOptions={renewalOptions}
          handleCancel={onClose}
          onNext={handleSettingsStep}
          editMode={editMode}
        />;
      case 1:
        return <PlanTiersForm
          isPilot={isPilot}
          initialTiers={tiers}
          employees={employees}
          accounts={accounts}
          editMode={editMode}
          onBack={handleBack}
          saveTiers={(tiers) => setTiers(tiers)}
          onNext={handleNext} />;
      case 2:
        return <PlanRestrictionsForm
          initialServiceSectorIds={serviceSectorIds}
          serviceSectors={serviceSectors}
          editMode={editMode}
          isLoading={isLoading}
          onBack={handleBack}
          saveServiceSectorIds={(serviceSectorIds) => setServiceSectorIds(serviceSectorIds)}
          onFinish={handlePlanSave} />;
      default:
        return <Typography>Unknown step</Typography>;
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label, index) => (
          <Step key={index}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Box sx={{ mt: 2, px: 5, pt: 2 }}>
        <StepContent step={activeStep} />
      </Box>
      <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
        <Box sx={{ flex: "1 1 auto" }} />
      </Box>
    </Box>
  );
};
