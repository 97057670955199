import { LoadingButton } from "@mui/lab";
import { Grid, Typography } from "@mui/material";
import { FC } from "react";
import { ReactComponent as ExtraordinaryCardSplash } from "../../assets/extraordinary-card-splash.svg";

export const Step5ReviewAndComplete: FC<{
  loading: boolean;
  onFinish: () => void;
}> = ({ loading, onFinish }) => {
  return (
    <Grid container item>
      <Grid item xs={4} mt={20}>
        <Typography variant="h4" fontSize={40} fontWeight={700} color="primary">
          Great!
        </Typography>
        <Typography variant="h4" fontSize={40} fontWeight={700}>
          You're all set!
        </Typography>
        <Typography variant="subtitle1" fontSize={18} my={3} width={400}>
          An invitation email has been sent to any staff you’ve invited to join
          Extraordinary
        </Typography>

        <LoadingButton
          loading={loading}
          onClick={onFinish}
          variant="contained"
          sx={{ mt: 5 }}
        >
          Finish
        </LoadingButton>
      </Grid>
      <Grid item xs={6} mt={-45} minHeight="110vh">
        <ExtraordinaryCardSplash />
      </Grid>
    </Grid>
  );
};
